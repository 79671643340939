import ExperimentalGrid from '../components/ExperimentalGrid';
import Experiment_04 from '../experiments/Experiment_04';

export function Experiment_p04() {
    return (
        <>
            <Experiment_04 />
        </>
    );
}
export default Experiment_p04;