import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF("assets/isometric_rooms.glb");
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004.geometry}
        material={materials["Material.019"]}
        position={[-3.11, 0.66, 3.38]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.05, 1, 0.3]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.TechHeavyKit147.geometry}
        material={materials["08 - Default"]}
        position={[-0.14, 0.9, -2.75]}
        scale={0.07}
      />
      <group scale={4}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_1.geometry}
          material={materials["Material.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_2.geometry}
          material={materials["Material.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_3.geometry}
          material={materials["Material.024"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_4.geometry}
          material={materials["Material.009"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane_5.geometry}
          material={materials["Material.070"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.buttons.geometry}
        material={materials.oven3}
        position={[-2.4, 2.27, -1.35]}
        scale={0.03}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Circle.geometry}
        material={materials.STOVE}
        position={[0.6, 1.54, -2.52]}
        scale={0.24}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle002.geometry}
          material={materials.STOVE}
          position={[-5, -0.02, 0]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle003.geometry}
          material={materials.STOVE}
          position={[-5, 0.18, 0]}
          scale={0.6}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle004.geometry}
          material={materials.STOVE}
          position={[-0.9, 0.3, -2.08]}
          scale={0.38}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Circle005.geometry}
          material={materials.STOVE}
          position={[-4.1, 0.24, -2.08]}
          scale={0.38}
        />
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bedroom.geometry}
        material={materials.bedroom}
        position={[-3.55, 3.8, 2.8]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane003.geometry}
        material={materials["Material.070"]}
        position={[0.03, 0, 0]}
        scale={4}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Desirefx_me_Mega_Kitbash_Detail_419.geometry}
        material={materials["01___Default.001"]}
        position={[-2.83, 1.08, 0.22]}
        scale={0.41}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Desirefx_me_Mega_Kitbash_Detail_491.geometry}
        material={materials["01___Default"]}
        position={[0.76, 0.35, 0.21]}
        scale={0.17}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cylinder001.geometry}
        material={materials["Material.020"]}
        position={[-3.1, 0.29, 3.5]}
        scale={0.21}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Canister_09003.geometry}
        material={materials["Material.071"]}
        position={[0.76, 2.31, 0.21]}
        scale={0.06}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Canister_09001.geometry}
        material={materials["plastic.071"]}
        position={[0.76, 2.31, 0.21]}
        scale={0.06}
      />
      <group position={[8, -0.2, -8]} scale={4}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023.geometry}
          material={materials["Material.048"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023_1.geometry}
          material={materials["Material.047"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023_2.geometry}
          material={materials["Material.046"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023_3.geometry}
          material={materials["Material.045"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane023_4.geometry}
          material={materials["Material.044"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.bed.geometry}
        material={materials["Material.043"]}
        position={[8, 0.11, -8.48]}
        scale={[1.35, 0.2, 2.76]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.storage.geometry}
        material={materials["Material.040"]}
        position={[5.67, 0.38, -10.98]}
        scale={[0.76, 0.36, 0.36]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.speepwell_shit.geometry}
        material={materials["Material.038"]}
        position={[8, 0.45, -8.58]}
        scale={[1.22, 0.22, 2.64]}
      />
      <group position={[3.17, 2.52, -3.03]} scale={0.02}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_16_1.geometry}
          material={materials["plastic.065"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_16_2.geometry}
          material={materials["metal.072"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_16_3.geometry}
          material={materials["white.016"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.couch.geometry}
        material={materials["Material.056"]}
        position={[0, 6.03, -10.3]}
        scale={[2.08, 1, 1]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube008.geometry}
        material={materials["Material.049"]}
        position={[-0.11, 6.95, -10.83]}
        rotation={[-0.18, 0.17, -0.02]}
        scale={[0.41, 0.33, 0.14]}
      />
      <group position={[0.76, 2.31, 0.21]} scale={0.06}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_09_1.geometry}
          material={materials["metal.079"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_09_2.geometry}
          material={materials["void.056"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pillow2.geometry}
        material={materials["Material.039"]}
        position={[7.41, 0.92, -10.93]}
        rotation={[-0.18, 0, 0]}
        scale={[0.54, 0.33, 0.2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pillow3.geometry}
        material={materials["Material.039"]}
        position={[8.08, 0.92, -10.56]}
        rotation={[-0.28, 0, 0]}
        scale={[0.4, 0.24, 0.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Canister_09002.geometry}
        material={materials["void.056"]}
        position={[0.76, 2.31, 0.21]}
        scale={0.06}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.ancient_creep.geometry}
        material={materials.ancient_creep}
        position={[10.48, 2.86, -11.7]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.45}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.desk.geometry}
        material={materials["Material.034"]}
        position={[4.69, 0.96, -8]}
        scale={[0.39, 1, 1.85]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.blanket.geometry}
        material={materials["Material.037"]}
        position={[8, 0.99, -7.56]}
        scale={[1.86, 1, 2.05]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Desirefx_me_Mega_Kitbash_Detail_489.geometry}
        material={materials["01___Default"]}
        position={[3.18, 4.05, -3.01]}
        scale={0.08}
      />
      <group
        position={[4.47, 1.41, -8]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.4, 0.74, 0.74]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane014.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane014_1.geometry}
          material={materials["Material.032"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.keyboard.geometry}
        material={nodes.keyboard.material}
        position={[4.8, 0.97, -7.88]}
        scale={[0.12, 1, 0.38]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mouse.geometry}
        material={nodes.mouse.material}
        position={[4.8, 0.98, -8.44]}
        scale={[0.06, 0.03, 0.04]}
      />
      <group
        position={[5.64, 0.45, -8]}
        rotation={[0, 0.22, 0]}
        scale={[0.43, 1, 0.44]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane011.geometry}
          material={materials["Material.031"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane011_1.geometry}
          material={materials["Material.030"]}
        />
      </group>
      <group position={[4.51, 3.62, -8]} scale={[0.08, 0.08, 2.51]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube049.geometry}
          material={materials["Material.029"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube049_1.geometry}
          material={materials["Material.028"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.table_leg2.geometry}
        material={materials["Material.035"]}
        position={[4.55, 0.43, -6.48]}
        scale={[0.25, 0.49, 0.25]}
      />
      <group position={[0, 5.66, -8]} scale={4}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane038.geometry}
          material={materials["Material.067"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane038_1.geometry}
          material={materials["Material.066"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane038_2.geometry}
          material={materials["Material.065"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane038_3.geometry}
          material={materials["Material.064"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.carpet.geometry}
        material={materials["Material.055"]}
        position={[1.52, 5.88, -6.61]}
        scale={[2.02, 1, 1.49]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.plant.geometry}
        material={materials["Material.063"]}
        position={[-2.75, 5.9, -10.81]}
        scale={0.23}
      >
        <group position={[-0.03, 1.36, 0.03]} scale={0.23}>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane037.geometry}
            material={materials["Material.062"]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plane037_1.geometry}
            material={materials["Material.061"]}
          />
        </group>
      </mesh>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.paintings.geometry}
        material={nodes.paintings.material}
        position={[-1.23, 8.9, -11.64]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.75, 0.85, 0.85]}
      />
      <group
        position={[-3.62, 8.19, -7.98]}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[1.48, 0.55, 0.85]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane036.geometry}
          material={materials["Material.060"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane036_1.geometry}
          material={materials["Material.059"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["paintings-2"].geometry}
        material={nodes["paintings-2"].material}
        position={[0.18, 9.21, -11.64]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.4, 0.46, 0.46]}
      />
      <group
        position={[-3.44, 9.32, -8]}
        rotation={[Math.PI, 0, Math.PI]}
        scale={[0.05, 0.05, 1.88]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube020.geometry}
          material={materials["Material.058"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Cube020_1.geometry}
          material={materials["Material.057"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["paintings-3"].geometry}
        material={nodes["paintings-3"].material}
        position={[0.86, 8.34, -11.64]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[0.43, 0.25, 0.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.table_plant.geometry}
        material={materials["Material.051"]}
        position={[2.98, 6.24, -10.58]}
        scale={[0.07, 0.11, 0.11]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.legs.geometry}
        material={nodes.legs.material}
        position={[1.73, 5.93, -9.61]}
        scale={0.17}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube007.geometry}
        material={materials["Material.049"]}
        position={[1.27, 6.95, -10.83]}
        rotation={[-0.18, -0.17, -0.09]}
        scale={[0.41, 0.33, 0.14]}
      />
      <group position={[3.17, 1.07, -3.03]} scale={0.03}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_67_1.geometry}
          material={materials["metal.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_67_2.geometry}
          material={materials["void.022"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_67_3.geometry}
          material={materials["plastic.023"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Canister_67_4.geometry}
          material={materials["plastic_shiny.009"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.chair001.geometry}
        material={materials["Material.056"]}
        position={[-2.08, 6.02, -7.8]}
        rotation={[-Math.PI, 1.44, -Math.PI]}
        scale={[2.08, 1, 1]}
      />
      <group
        position={[4.26, 2.59, -8]}
        rotation={[0, 0, -Math.PI / 2]}
        scale={[0.87, 1, 1.82]}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane022.geometry}
          material={materials["Material.042"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane022_1.geometry}
          material={materials["Material.041"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube006.geometry}
        material={materials["Material.049"]}
        position={[-2.42, 6.95, -6.53]}
        rotation={[-2.31, 1.21, 2.3]}
        scale={[0.41, 0.33, 0.14]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.storage2.geometry}
        material={materials["Material.040"]}
        position={[10.54, 0.38, -10.98]}
        scale={[0.76, 0.36, 0.36]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pillow.geometry}
        material={materials["Material.039"]}
        position={[8.62, 0.92, -10.93]}
        rotation={[-0.28, 0, 0]}
        scale={[0.54, 0.33, 0.2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.green_house0001.geometry}
        material={materials.green_house0001}
        position={[5.47, 2.86, -11.75]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={1.45}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.table_leg1.geometry}
        material={materials["Material.035"]}
        position={[4.55, 0.43, -9.56]}
        scale={[0.25, 0.49, 0.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.legs_2.geometry}
        material={nodes.legs_2.material}
        position={[-1.74, 5.92, -7.26]}
        rotation={[-Math.PI, 1.44, -Math.PI]}
        scale={0.17}
      />
      <group position={[1.53, 6.45, -6.62]} scale={[0.86, 0.63, 0.93]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane030.geometry}
          material={materials["Material.054"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.Plane030_1.geometry}
          material={materials["Material.053"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.table_pot.geometry}
        material={materials["Material.052"]}
        position={[2.97, 5.85, -10.31]}
        scale={0.28}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Cube009.geometry}
        material={materials["Material.049"]}
        position={[-1.4, 6.99, -10.72]}
        rotation={[-0.27, 0.5, 0]}
        scale={[0.41, 0.33, 0.14]}
      />
    </group>
  );
}

useGLTF.preload("assets/isometric_rooms.glb");

