// import StickyFooter from './Footer';
// import Gallery from './Gallery';
// import MyCarousel from './Carousel';
// import ExperimentalGrid from './ExperimentalGrid';


import React from "react";
import styled from "styled-components";
import { useRef, useState, useEffect, Suspense } from 'react'
import { applyProps } from '@react-three/fiber'

// import { Suspense } from "react";
import Typography from "@mui/material/Typography";
import TimePicker from "@mui/lab/TimePicker";
import DateAdapter from "@mui/lab/AdapterDateFns";
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import { LocalizationProvider } from '@mui/x-date-pickers'
import { Canvas, useFrame } from '@react-three/fiber'
import { Grid, Html, Plane, Sphere } from '@react-three/drei'
import { ringGeometry, planeGeometry } from "@react-three/drei";
import { Environment, Lightformer, MeshReflectorMaterial, Reflector, ContactShadows } from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { OrbitControls, Stats, Text } from "@react-three/drei";
import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/web"

import { Model } from "../experiments/Model";
import { Pendulums } from "../experiments/Pendulums";

import axios from "axios";
// import './Contact.css'
const Loading = <Html><div>LOADING...</div></Html>;



function Ground2(props) {
  // const [distortionTexture] = useTexture(['assets/SurfaceImperfections003_1K_Normal.jpg'])
  console.log("PROPS: ", props);
  const refMat = useRef();
  applyProps(refMat, {color: props.color});
  

  return (
    <group position={[0, -.01, 0]}>
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial ref={refMat}
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={90}
          metalness={.2}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#050505"
        />
      </mesh>
    </group>
  
   )
}
export function Experiment_02() {

    const [toggle, set] = useState(0)

    const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])

    const [myText, setMyText] = useState("Hello World");
    const [bgColor, setBgColor] = useState("#000");

    const adjustBackground = (color) => {
      console.log("adjusting background color to " + color)
      setBgColor(color);
      // oc.background = new THREE.Color(color);
    };
    const oc = useRef();
    return (
        <>
        <Canvas style={{display: 'flex', width: "100%", height: "100%"}} camera={{ fov: 45, position: [20, 5, 15] }}>
        <color attach="background" args={['#000']} />
        
        <Suspense fallback={ Loading }> 
        <hemisphereLight intensity={0.25} />
        <ambientLight intensity={0.5} />
        <spotLight position={[5, 10, 10]} angle={0.45} penumbra={1} intensity={5} color="orange" lookAt={[10,0,0]}/>
        <spotLight position={[30, 10, -10]} angle={0.45} penumbra={1} intensity={5} color="blue" lookAt={[15,0,0]}/>
        <Ground2 color={bgColor}/>
        <Pendulums />

        {/* <Plane position={[8,0,0]} rotation={[-Math.PI/2,0,0]} scale={[20,10,1]}> 
          <meshStandardMaterial color="#aaa" />
        </Plane> */}
{/*         
        <Environment resolution={2048}>

          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />

          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />

          <Lightformer form="ring" color="blue" intensity={10} scale={2} position={[10, 5, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
        */}

        <EffectComposer multisampling={8}>
          <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
          <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
        </EffectComposer> 
      <OrbitControls ref={oc} target={[4,6,-4]}/>
      <Stats/>
      </Suspense>
        </Canvas>
        
        {/* <StickyFooter /> */}
        </>
    )
}
export default Experiment_02;