
import React from "react";
import styled from "styled-components";
import { useRef, useState,  Suspense } from 'react'
// import { Suspense } from "react";

import { useControls } from 'leva'

import { Canvas, useFrame } from '@react-three/fiber'
import { applyProps } from '@react-three/fiber'

import { Html, Sphere } from '@react-three/drei'
import { Reflector, OrbitControls, Stats, useTexture, Text,MeshReflectorMaterial } from "@react-three/drei";
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'

import { useSpring } from "@react-spring/core"
import { a } from "@react-spring/web"

import { PeopleSphere} from "./Sphere_v6";
import { PeopleSphere2} from "./Sphere_v7";

// import './Contact.css'
const Loading = <Html><div>LOADING...</div></Html>;

function Ground(props) {
  const [floor, normal] = useTexture(['assets/SurfaceImperfections003_1K_var1.jpg', 'assets/SurfaceImperfections003_1K_Normal.jpg'])
  return (
    <Reflector resolution={1024} args={[8, 8]} {...props}>
      {(Material, props) => <Material color="#f0f0f0" metalness={0} roughnessMap={floor} normalMap={normal} normalScale={[2, 2]} {...props} />}
    </Reflector>
  )
}
function Ground2(props) {
  // const [distortionTexture] = useTexture(['assets/SurfaceImperfections003_1K_Normal.jpg'])
  console.log("PROPS: ", props);
  const refMat = useRef();
  applyProps(refMat, {color: props.color});
  

  return (
    <group position={[0, -1.2, 0]}>
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial ref={refMat}
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={90}
          metalness={.2}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#050505"
        />
      </mesh>
    </group>
  
   )
}
function Box(props) {
    const ref = useRef()
  
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
  
    useFrame((state) => {
      ref.current.rotation.x += props.up ? -0.1 : 0
      ref.current.rotation.x += props.down ? 0.1 : 0
      ref.current.rotation.y += props.left ? -0.1 : 0
      ref.current.rotation.y += props.right ? 0.1 : 0
    }, [])
  
    return (
      <mesh
        position={props.position}
        ref={ref}
        scale={clicked ? 1.5 : 1}
        onClick={(event) => click(!clicked)}
        onPointerOver={(event) => hover(true)}
        onPointerOut={(event) => hover(false)}>
        <boxGeometry args={[2, 2, 2]} />
        <meshStandardMaterial color={hovered ? 'red' : 'blue'} />
      </mesh>
    )
  }
export function Experiment_01() {

    const [toggle, set] = useState(0)

    // const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])

    // const [myText, setMyText] = useState("Hello World");
    const oc = useRef();
    const [autorotate, setAutoRotate] = useState(true);


  const data = useControls({
    AutoRotate: {
      value: true,
      onChange: (v) => {
        // imperatively update the world after Leva input changes
        console.log(v)
        setAutoRotate(v)
      }
    },
   
  })
  applyProps(oc, { autoRotate: autorotate });
    return (
        <>
        <Canvas style={{display: 'flex', width: "100%", height: "100%"}} camera={{ fov: 45, position: [3, 1, 3] }}>
        <Suspense fallback={ Loading }> 
        <color attach="background" args={['black']} />
        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />

        
        {/* ============================ */}
        {/* PeopleSphere is Sphere_v6 >> more classy/orig approach using gltf pipeline,  also interaction design added at the moment */}
        {/* PeopleSphere2 is Sphere_v7 >> more tweakable approach to use gltf but lacking adding interaction design easily  */}
        {/* ============================ */}


        <PeopleSphere />
        {/* <PeopleSphere2 /> */}

        
        {/* <Ground mirror={1} blur={[500, 100]} mixBlur={75} mixStrength={.3} rotation={[-Math.PI / 2, 0, Math.PI / 2]} position-y={-1.3} scale={1.5} /> */}
        <Ground2 color='#000'/>

        <EffectComposer multisampling={8}>
          <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
          <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
        </EffectComposer>

      <OrbitControls ref={oc} target={[0,0,0]}
        enablePan={true}
        enableZoom={true}
        autoRotate={autorotate}
      />
      
      <Stats/>
      </Suspense>
        </Canvas>
        </>
    )
}
export default Experiment_01;