//import Gallery from './Gallery';
// import Navbar from '../components/Nav';

import MyCarousel from '../components/Carousel';
import StickyFooter from '../components/Footer';
import VideoGallery from '../components/VideoGallery';
import AiVideoGallery from '../components/AiVideoGallery';
import VideoGallery2 from '../components/VideoGallery2';
import {  DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

// import getVideos from '../serviceVideoGallery.js';
// import getRTVideos from '../serviceRTGallery.js';


import './Home.css';

export function AiVideos() {

    // const videos = getVideos();
    return (
        <>
        {/* <MyCarousel /> */}
        <AiVideoGallery name="main" columns="3"/>
        {/* <Element name="RT" className="element">
        <VideoGallery2 name="RT" />
        </Element> */}
        <StickyFooter />
        </>
    );
}
export default AiVideos;