/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 DeLoarean.gltf -o DeLorean.jsx
*/

import React, { useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'

import { useGLTF } from '@react-three/drei'

export function DeLorean(props) {
  const { nodes, materials } = useGLTF('assets/delorean/DeLorean.gltf')
  const ref = useRef()
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    ref.current.rotation.x = (-Math.PI / .5 + Math.cos(t / 4) / 8)
    ref.current.rotation.y = Math.sin(t / 4) / 8
    ref.current.rotation.z = (1 + Math.sin(t / 1.5)) / 20
    ref.current.position.y = (2 + Math.sin(t / 1.5)) / 1.5
  })
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0.68, 0.65]} scale={0.43} ref={ref}>
        <mesh geometry={nodes['001-0-1'].geometry} material={materials.wires_bright} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['002-0-2'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['003-0-3'].geometry} material={materials.engineThurusterDesign} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['006-0-6'].geometry} material={materials.seat} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['007-0-7'].geometry} material={materials.seat} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['008-0-8'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['009-0-9'].geometry} material={materials.engine_rubber_pipes} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['010-0-10'].geometry} material={materials.engine_rubber_pipes} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['011-0-11'].geometry} material={materials.wires_color2} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['012-0-12'].geometry} material={materials.wires} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['014-0-14'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['017-0-17'].geometry} material={materials.tyres} position={[3.95, 0.15, 5.54]} scale={2.31} />
        <mesh geometry={nodes['019-0-19'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['020-0-20'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['021-0-21'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['022-0-22'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['023-0-23'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['024-0-24'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['025-0-25'].geometry} material={materials.red_light_glass} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['026-0-26'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['027-0-27'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['028-0-28'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['029-0-29'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['030-0-30'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['031-0-31'].geometry} material={materials.numberPlate} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['032-0-32'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['033-0-33'].geometry} material={materials.numberPlate} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['034-0-34'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['035-0-35'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['036-0-36'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['037-0-37'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['038-0-38'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['039-0-39'].geometry} material={materials.bumper} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['040-0-40'].geometry} material={materials.glass} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['041-0-41'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['042-0-42'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['043-0-43'].geometry} material={materials.bumper} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['044-0-44'].geometry} material={materials.dark_plastic_base_side} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['045-0-45'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['046-0-46'].geometry} material={materials.base} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['047-0-47'].geometry} material={materials['bumper metal']} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['048-0-48'].geometry} material={materials.dark_plastic_base_side} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['049-0-49'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['050-0-50'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['051-0-51'].geometry} material={materials.headlight_chrome} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['052-0-52'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['053-0-53'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['054-0-54'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['055-0-55'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['056-0-56'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['057-0-57'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['058-0-58'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['059-0-59'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['060-0-60'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['061-0-61'].geometry} material={materials.indicator_orange} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['062-0-62'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['063-0-63'].geometry} material={materials.wires_color} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['064-0-64'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['065-0-65'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['066-0-66'].geometry} material={materials.wires_color} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['067-0-67'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['068-0-68'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['069-0-69'].geometry} material={materials.glass} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['070-0-70'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['071-0-71'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['072-0-72'].geometry} material={materials.indicator_orange} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['073-0-73'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['074-0-74'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['075-0-75'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['076-0-76'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['077-0-77'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['078-0-78'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['079-0-79'].geometry} material={materials.dark_plastic_base_side} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['080-0-80'].geometry} material={materials.bumper} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['081-0-81'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['082-0-82'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['083-0-83'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['084-0-84'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['085-0-85'].geometry} material={materials['bumper metal']} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['086-0-86'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['087-0-87'].geometry} material={materials.glass} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['088-0-88'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['089-0-89'].geometry} material={materials.wires} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['090-0-90'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['091-0-91'].geometry} material={materials.wires_color} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['092-0-92'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['093-0-93'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['094-0-94'].geometry} material={materials.headlight_glass} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['095-0-95'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['096-0-96'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['097-0-97'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['098-0-98'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['099-0-99'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['100-0-100'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['101-0-101'].geometry} material={materials.carbody} position={[0, -1.57, -1.51]} scale={2.31} />
        <mesh geometry={nodes['102-0-102'].geometry} material={materials.chrome_logo} position={[0, -1.57, -1.51]} scale={2.31} />
        <group position={[4.33, 0.13, 5.53]} scale={2.31}>
          <mesh geometry={nodes['013-0-13'].geometry} material={materials.chrome_tyre} />
          <mesh geometry={nodes['013-0-13_1'].geometry} material={materials.tyres} />
        </group>
        <group position={[-4.33, 0.13, 5.53]} scale={2.31}>
          <mesh geometry={nodes['000-0-0'].geometry} material={materials.chrome_tyre} />
          <mesh geometry={nodes['000-0-0_1'].geometry} material={materials.tyres} />
        </group>
        <group position={[4.49, 0.37, -8.13]} scale={2.31}>
          <mesh geometry={nodes['005-0-5'].geometry} material={materials.chrome_tyre} />
          <mesh geometry={nodes['005-0-5_1'].geometry} material={materials.tyres} />
        </group>
        <group position={[-4.49, 0.37, -8.13]} scale={2.31}>
          <mesh geometry={nodes['004-0-4'].geometry} material={materials.chrome_tyre} />
          <mesh geometry={nodes['004-0-4_1'].geometry} material={materials.tyres} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('assets/delorean/DeLoarean.gltf')
