
import React from "react";
import styled from "styled-components";
import { useRef, useState, useEffect, Suspense } from 'react'
import { Canvas, useFrame,useThree } from '@react-three/fiber'
import { Html, Sphere } from '@react-three/drei'
import { OrbitControls, Stats, useTexture,CameraShake } from "@react-three/drei";
import { ringGeometry, planeGeometry, Text } from "@react-three/drei";
import { Environment, Lightformer, MeshReflectorMaterial, Reflector, ContactShadows } from '@react-three/drei'
import { KernelSize } from 'postprocessing'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import { useSpring } from "@react-spring/core"
import { useControls, Leva, button, buttonGroup} from 'leva'
import { applyProps } from '@react-three/fiber'
import * as THREE from 'three'

import { a } from "@react-spring/web"

// import {HondaCivic} from './HondaCivic'
// import {HondaCivic2} from './HondaCivic2'
import {HondaCivic} from './Honda_Civic'
import { DeLorean } from "./DeLorean";
import {TyreThrust} from './TyreThrust';
import { Cybertruck } from "./Cybertruck";
import {Experience} from './Experience';

import './experiments.css'

const Loading = <Html><div>LOADING...</div></Html>;

function Ground(props) {
  const [floor, normal] = useTexture(['assets/SurfaceImperfections003_1K_var1.jpg', 'assets/SurfaceImperfections003_1K_Normal.jpg'])
  return (
    <Reflector resolution={1024} args={[8, 8]} {...props}>
      {(Material, props) => <Material color="#f0f0f0" metalness={0} roughnessMap={floor} normalMap={normal} normalScale={[1, 1]} {...props} />}
    </Reflector>
    
  )
}

function Ground2(props) {
  // const [distortionTexture] = useTexture(['assets/SurfaceImperfections003_1K_Normal.jpg'])
  console.log("PROPS: ", props);
  const refMat = useRef();
  applyProps(refMat, {color: props.color});
  

  return (
    <group position={[0, -.01, 0]}>
      <mesh rotation={[-Math.PI / 2, 0, 0]}>
        <planeGeometry args={[50, 50]} />
        <MeshReflectorMaterial ref={refMat}
          blur={[300, 100]}
          resolution={2048}
          mixBlur={1}
          mixStrength={90}
          metalness={.2}
          roughness={1}
          depthScale={1.2}
          minDepthThreshold={0.4}
          maxDepthThreshold={1.4}
          color="#050505"
        />
      </mesh>
    </group>
  
   )
}
function Box(props) {
    const ref = useRef()
  
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
  
    useFrame((state) => {
      ref.current.rotation.x += props.up ? -0.1 : 0
      ref.current.rotation.x += props.down ? 0.1 : 0
      ref.current.rotation.y += props.left ? -0.1 : 0
      ref.current.rotation.y += props.right ? 0.1 : 0
    }, [])
  
    return (
      <mesh
        position={props.position}
        ref={ref}
        scale={clicked ? 1.5 : 1}
        onClick={(event) => click(!clicked)}
        onPointerOver={(event) => hover(true)}
        onPointerOut={(event) => hover(false)}>
        <boxGeometry args={[2, 2, 2]} />
        <meshStandardMaterial color={hovered ? 'red' : 'blue'} />
      </mesh>
    )
  }

  function Rig({ children }) {
    const ref = useRef()
    const vec = new THREE.Vector3()
    const { camera, mouse } = useThree()
    useFrame(() => {
      camera.position.lerp(vec.set(mouse.x * 10, 2, 15), 0.05)
      ref.current.position.lerp(vec.set(mouse.x * 1, mouse.y * 0.1, 0), 0.1)
      ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (-mouse.x * Math.PI) / 20, 0.1)
    })
    return <group ref={ref}>{children}</group>
  }
export function Experiment_04() {

    // const [toggle, set] = useState(0)
    const [hex, setHex] = useState("#fff");
    const [toggle, setToggle] = useState(true);
    const [{ x }] = useSpring({ x: toggle, config: { mass: 5, tension: 1000, friction: 50, precision: 0.0001 } }, [toggle])
    const [bgColor, setBgColor] = useState("#000");
    const [myText, setMyText] = useState("Hello World");
    const r1 = useRef();
    const r2 = useRef();
    const r3 = useRef();
    const rings = [r1, r2, r3];
    const oc = useRef();
    const [autorotate, setAutoRotate] = useState(true);
    const toggleAutoRotate = () => {
      setAutoRotate((prev) => !prev);
      oc.autoRotate = !oc.autoRotate;
    };
    const randomizeRingGeoPositions = () => {
      console.log("randomizing ring geo positions")
      // iterate through the rings and set their positions to random values
      for (let i = 0; i < rings.length; i++) {
        if (rings[i].current) {
          rings[i].current.position.x = Math.random() * 10 - 5;
          rings[i].current.position.z = Math.random() * 10 - 5;
          rings[i].current.position.y = Math.random() * 0.2;
          var ringeo = rings[i].current.geometry;
          console.log(ringeo.parameters.innerRadius);
          rings[i].current.geometry.parameters.innerRadius = Math.random() * 2;
          rings[i].current.geometry.parameters.outerRadius = (Math.random()*2.1) + rings[i].current.geometry.parameters.innerRadius;
          rings[i].current.geometry.parameters.thetaSegments = Math.floor(Math.random() * 7) + 3;
          rings[i].current.geometry.parameters.phiSegments = Math.floor(Math.random() * 7) + 3;
          rings[i].current.geometry.parameters.thetaStart = Math.random() * 2 * Math.PI;
          rings[i].current.geometry.parameters.thetaLength = Math.random() * 2 * Math.PI;
          rings[i].current.material.color = new THREE.Color(Math.random(), Math.random(), Math.random());
        }
      }     
    };
    const adjustBackground = (color) => {
      console.log("adjusting background color to " + color)
      setBgColor(color);
      // oc.background = new THREE.Color(color);
    };
    const data = useControls("ENVIRONMENT",{
      AutoRotate: {
        value: true,
        onChange: (v) => {
          // imperatively update the world after Leva input changes
          console.log(v)
          setAutoRotate(v)
        }
      },
      // Randomize: {
      //   value: false,
      //   onChange: (v) => {
      //     // imperatively update the world after Leva input changes
      //     console.log("going to randommize ring geo positions")
      //     randomizeRingGeoPositions()
      //   }
      // },
      "RANDOMIZE SHAPES": button(() => randomizeRingGeoPositions(), ),
      // "GREEN": button(() => randomizeRingGeoPositions(), { color: "#f00" , label: "GREEN"}),
      

      // bodyColor: { value: "#fff", label: "Body Color" ,
      //   onClick: (v) => {
      //     console.log(v)
      //   }
      // },
      "BACKGROUND": buttonGroup({
        "Dark": () => {adjustBackground('#aaa')},
        "Grey": () => {adjustBackground('#ccc')},
        "Bright": () => {adjustBackground('#eee')},
      }),
      
    })
    
    return (
        <>
        <Canvas style={{display: 'flex', width: "100%", height: "100%"}} camera={{ position: [10, 0, 20], fov: 45 }}>
        <color attach="background" args={['#000']} />
        {/* <OrbitControls enableZoom={false} enablePan={false} enableRotate={false} target={[0,0,0]} /> */}

        <Suspense fallback={ Loading }> 
        {/* <Environment preset="sunset" background /> */}
        
        <hemisphereLight intensity={0.25} />

        <ambientLight intensity={0.5} />
        <spotLight position={[10, 10, 10]} angle={0.15} penumbra={1} />
        <pointLight position={[-10, -10, -10]} />

        {/* GROUNDS  */}
        {/* <Ground mirror={1} blur={[500, 100]} mixBlur={75} mixStrength={.7} rotation={[-Math.PI / 2, 0, Math.PI / 2]} position-y={-.01} scale={1.5} /> */}
        <ContactShadows resolution={1024} frames={1} position={[0, .01, 0]} scale={15} blur={0.5} opacity={1} far={20} />
        <Ground2 color={bgColor}/>
        
        {/* RING GEOS */}
        <mesh ref={r1} scale={4} position={[-3, 0, -1]} rotation={[-Math.PI / 2, 0, Math.PI / 2.5]}>
          <ringGeometry args={[0.9, .95, 3, 1]} />
          <meshStandardMaterial color="yellow" roughness={0.75} />
        </mesh>
        <mesh ref={r2} scale={3} position={[3, 0, 3]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          {/* ring geo args , inner radius, outer radius, numpoints, unknown */}
          <ringGeometry args={[0.9, 1, 3, 3]} />
          <meshStandardMaterial color="green" roughness={0.75} />
        </mesh>
        <mesh ref={r3} scale={3} position={[5, 0, -5]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
          {/* ring geo args , inner radius, outer radius, numpoints, unknown */}
          <ringGeometry args={[1.3, 1.35, 50, 3]} />
          <meshStandardMaterial color="red" roughness={0.75} />
        </mesh>
        
        {/* CAR GEO */}
        {/* <HondaCivic scale={1.5}/> */}
        <Experience />
        <TyreThrust />
        {/* <DeLorean /> */}
        
        <Environment resolution={2048}>
          {/* Ceiling */}
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -9]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, -3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 0]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 3]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 6]} scale={[10, 1, 1]} />
          <Lightformer intensity={2} rotation-x={Math.PI / 2} position={[0, 4, 9]} scale={[10, 1, 1]} />
          {/* Sides */}
          <Lightformer intensity={2} rotation-y={Math.PI / 2} position={[-50, 2, 0]} scale={[100, 2, 1]} />
          <Lightformer intensity={2} rotation-y={-Math.PI / 2} position={[50, 2, 0]} scale={[100, 2, 1]} />
          {/* Key */}
          <Lightformer form="ring" color="blue" intensity={10} scale={2} position={[10, 5, 10]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
        <EffectComposer multisampling={8}>
          <Bloom kernelSize={3} luminanceThreshold={0} luminanceSmoothing={0.4} intensity={0.6} />
          <Bloom kernelSize={KernelSize.HUGE} luminanceThreshold={0} luminanceSmoothing={0} intensity={0.5} />
        </EffectComposer>
        
      <OrbitControls ref={oc} target={[0,0,0]} autoRotate={autorotate} />
      <Stats className="stats" /> {/* use a custom class name */}
      </Suspense>
      {/* <CameraShake yawFrequency={0.2} pitchFrequency={0.2} rollFrequency={0.2} /> */}

      </Canvas>
        <Leva className="leva" 
          // theme={myTheme} // you can pass a custom theme (see the styling section)
          //fill // default = false,  true makes the pane fill the parent dom node it's rendered in
          flat = {false} // default = false,  true removes border radius and shadow
          //oneLineLabels // default = false, alternative layout for labels, with labels and fields on separate rows
          //hideTitleBar // default = false, hides the GUI header
          collapsed = {false} // default = false, when true the GUI is collpased
          //hidden // default = false, when true the GUI is hidden
        
        />
        {/* <StickyFooter /> */}
        </>
    )
}
export default Experiment_04;