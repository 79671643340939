/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 tyreThrust.gltf -o tyre.jsx
*/

import React, { useRef } from 'react'
import { useLoader } from '@react-three/fiber'; 
import { Canvas, useFrame } from "@react-three/fiber";
import { applyProps } from '@react-three/fiber'

import { useGLTF, shaderMaterial } from '@react-three/drei'
import { useTexture } from "@react-three/drei";
import { TextureLoader } from 'three/src/loaders/TextureLoader'; 
import * as THREE from 'three'
import { extend } from '@react-three/fiber'
import { useControls, Leva, button, buttonGroup} from 'leva'

import stripesVertex from "./shaders/stripes.vertex.glsl";
import stripesFragment from "./shaders/stripes.fragment.glsl";
import disksVertex from "./shaders/disks.vertex.glsl";
import disksFragment from "./shaders/disks.fragment.glsl";

const DisksShaderMaterial = shaderMaterial(
  {
    uAlpha: 0.5,
    uMultiplier: 42,
    uColorA: new THREE.Color(0x000000),
    uColorB: new THREE.Color(0x000000),
    uTime: 0,
  },
  disksVertex,
  disksFragment
);
const StripesShaderMaterial = shaderMaterial(
  {
    uAlpha: 0.5,
    uMultiplier: 42,
    uColorA: new THREE.Color(0x000000),
    uColorB: new THREE.Color(0x000000),
    uTime: 0,
  },
  stripesVertex,
  stripesFragment
);
extend({ DisksShaderMaterial });

extend({ StripesShaderMaterial })

const MyMaterial = shaderMaterial(
  { time: 0, color: new THREE.Color(0.2, 0.0, 0.1) },
  // vertex shader
  /*glsl*/`
    varying vec2 vUv;
    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }
  `,
  // fragment shader
  /*glsl*/`
    uniform float time;
    uniform vec3 color;
    varying vec2 vUv;
    void main() {
      gl_FragColor.rgba = vec4(0.2 + 0.1 * sin(vUv.yxx + time) + color, 1.0);
    }
  `
)

// declaratively
extend({ MyMaterial })



export function TyreThrust(props) {
  
  const ref = useRef();
  const mesh = useRef();

  const { nodes, materials } = useGLTF('assets/delorean/tyreThrust.gltf')
  const noise1 = useTexture("assets/delorean/noise1.jpg");
  const noise2 = useTexture("assets/delorean/noise2.png");
  const ramp1 = useTexture("assets/delorean/ramp1.png");
  const ramp2 = useTexture("assets/delorean/ramp2.png");
  const [icon_00, icon_01] = useLoader(TextureLoader, [ 
    'assets/people/assets/tiles/ptr_00.png',
    'assets/people/assets/tiles/ptr_01.png', 
  ]);

  noise1.wrapS = noise1.wrapT = THREE.RepeatWrapping;
 ramp2.wrapT = THREE.RepeatWrapping;
 ramp1.wrapT = THREE.RepeatWrapping;
 ramp1.repeatY = ramp2.repeatY = 1;
  // rotate ramp2 texture 90 degrees
  // ramp2.rotation = Math.PI / 2;

  noise1.repeat.x=3;

  useFrame((state) => {
    // Animate the uv offset of the texture
    // noise1.offset.x += 0.01;
    noise1.offset.y += 0.02;
    noise1.repeat.y =.1;
    if (ref.current) {
      ref.current.uTime = state.clock.elapsedTime;
    }

    // Rotate the mesh slightly
    //mesh.current.rotation.x += 0.01;
    //mesh.current.rotation.y += 0.01;
  });

  //applyProps(materials['Material.001'], { map: noise1,  transparent:true, opacity:0.5, polygonOffset:true, polygonOffsetFactor:-.1 });
  applyProps(materials['Material.001'], { color:'#00f', map:noise1, alphaMap:ramp1,  transparent:true, opacity:.65 });
  const { shader } = useControls({
    shader: {
      options: ["none", "disks", "stripes"],
    },
  });
  const disksControls = useControls("disks", {
    alpha: {
      min: 0,
      max: 1,
      value: 0.5,
    },
    multiplier: {
      min: 1,
      max: 142,
      value: 12,
    },
    colorA: "#FF0000",
    colorB: "#0000FF",
  });
  const stripesControls = useControls("stripes", {
    alpha: {
      min: 0,
      max: 1,
      value: 0.5,
    },
    multiplier: {
      min: 1,
      max: 142,
      value: 42,
    },
    colorA: "#FF0000",
    colorB: "#FFFF00",
  });



  // materials.tyres = new StripesShaderMaterial();

  return (
    <group {...props} dispose={null}>
      {/* <mesh ref={mesh} geometry={nodes.Cylinder.geometry} material={materials['Material.001']} position={[0, 0.6, 0]} scale={[0.46, 0.47, 0.46]} /> */}
      <mesh ref={mesh} geometry={nodes.Cylinder.geometry}  position={[0, 0.6, 0]} scale={[0.46, 0.47, 0.46]} >
      <stripesShaderMaterial
            ref={ref}
            transparent
            uAlpha={stripesControls.alpha}
            uMultiplier={stripesControls.multiplier}
            uColorA={stripesControls.colorA}
            uColorB={stripesControls.colorB}
          />

      </mesh>

      <group position={[0, 0.5, 0]}>
        {/* <mesh geometry={nodes.tyremesh.geometry} material={materials.chrome_tyre} /> */}
        <mesh geometry={nodes.tyremesh_1.geometry} material={materials.tyres} />
        {shader === "disks" && (
          <mesh geometry={nodes.tyremesh.geometry}>
          <disksShaderMaterial
              ref={ref}
              transparent
              uAlpha={disksControls.alpha}
              uMultiplier={disksControls.multiplier}
              uColorA={disksControls.colorA}
              uColorB={disksControls.colorB}
            />
          </mesh>
        )}
        {shader === "stripes" && (
          <mesh geometry={nodes.tyremesh.geometry}>
          <stripesShaderMaterial
              ref={ref}
              transparent
              uAlpha={stripesControls.alpha}
              uMultiplier={stripesControls.multiplier}
              uColorA={stripesControls.colorA}
              uColorB={stripesControls.colorB}
            />
          </mesh>
        )}
      </group>
    </group>
  )
}

useGLTF.preload('assets/delorean/tyreThrust.gltf')
