import ExperimentalGrid from '../components/ExperimentalGrid';
import Experiment_03 from '../experiments/Experiment_03';

export function Experiment_p03() {
    return (
        <>
            <Experiment_03 />
        </>
    );
}
export default Experiment_p03;