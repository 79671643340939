/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 pendulums.gltf -o pendulums.jsx
*/

import React, { useRef, useState } from 'react'
import { applyProps } from '@react-three/fiber'
import { Canvas, useFrame } from '@react-three/fiber'
import { Sphere, useGLTF,Plane } from '@react-three/drei'

import { useLoader } from '@react-three/fiber'; 
import { TextureLoader } from 'three/src/loaders/TextureLoader'; 
import * as THREE from 'three'
import { useControls, button, Leva,buttonGroup,folder } from 'leva'

import { NodeToyMaterial, NodeToyTick } from '@nodetoy/react-nodetoy';
import {data as shader0} from './shaders/nodeToy0'
import {shaderData1 as shader1} from './shaders/nodeToy1'
import {shaderData2 as shader2} from './shaders/nodeToy2'


export function Pendulums(props) {
  const { nodes, materials } = useGLTF('assets/pendulums.gltf')
  const group1 = useRef()
  const ref0 = useRef();const ref1 = useRef(); const ref2 = useRef();const ref3 = useRef();const ref4= useRef();const ref5 = useRef();const ref6 = useRef();const ref7 = useRef();const ref8 = useRef();const ref9 = useRef();
  const pendulums = [ref0, ref1, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9];
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    // update the angle using a sine function
    angle.current += speed;
    // materialProps._time = angle.current;
    // materialProps.TimeScale = angle.current;
    // rotate the mesh around the z-axis by the angle
    //ref.current.rotation.x = amplitude * Math.sin(angle.current);
    Object.values(pendulums).forEach((pendulum) => {
      pendulum.current.rotation.x = amplitude * Math.sin((angle.current+(pendulum.current.position.x*offset)));
    })

  })
  const texture1 = useLoader(THREE.TextureLoader, 'assets/delorean/noise2.png')
  const texture2 = useLoader(THREE.TextureLoader, 'assets/delorean/ramp1.jpg')
  // const myMat = new NodeToyMaterial({shaderData: shader1, uniforms: {tex1: {value: texture1}, tex2: {value: texture2}}})
  const angle = useRef(0); // create a reference to store the current angle
  const [speed, setSpeed] = useState(0.01); // set the speed of the swing
  const [amplitude, setAmp] = useState(Math.Pi /4); // set the amplitude of the swing
  const [offset,setOffset]  = useState(.2);

  const bodyColors = useControls("Swing Controls",{
    Speed: { value: 0.1, min: 0, max: 1, onChange: (v) => {setSpeed(v)} },
    
    Amplitude: { value: .33, min: 0, max: 1, onChange: (v) => {setAmp(v)} },
    Delay: { value: .27, min: 0, max: 1, onChange: (v) => {setOffset(v)} },

  })
  const materialProps = useControls({
    _time: { value: 1, min: 0.5, max: 20, step: 0.1 }, // Level of transmission
    // TimeScale: { value: 1, min: 0.5, max: 20, step: 0.1 }, // Level of transmission

  })
  applyProps(materials["sphere"], { metalness: .8, roughness: 0.35, color:'#666' }, [])
  applyProps(materials["cylinder"], { color:'#333' }, [])

  materials["mymat"] = materials.sphere.clone();
  // materials["mymat"].name = "mymat";
  applyProps(materials["mymat"], { map: texture1,});
  // materials["mymat2"] = NodeToyMaterial({shaderData: shader1, uniforms: {tex1: {value: texture1}, tex2: {value: texture2}}})

  return (
    

    <group {...props} dispose={null}>
      <group  position={[0,0, 0]} >
        
        {/* <Plane position={[8,1,0]} rotation={[-Math.PI/2,0,0]} scale={[5,5,1]} material={materials["mymat2"]} />  */}
          
      </group>
      <group  position={[0,9, 0]} ref={ref0}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[2,9, 0]} ref={ref1}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[4,9, 0]} ref={ref2}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[6,9, 0]} ref={ref3}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[8,9, 0]} ref={ref4}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[10,9, 0]} ref={ref5}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[12,9, 0]} ref={ref6}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[14,9, 0]} ref={ref7}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[16,9, 0]} ref={ref8}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <group  position={[18,9, 0]} ref={ref9}>
        <mesh geometry={nodes.Cylinder_1.geometry} material={materials.cylinder} />
        <mesh geometry={nodes.Cylinder_2.geometry} material={materials.sphere} />
      </group>
      <NodeToyTick/>
    </group>
  )
}

useGLTF.preload('assets/pendulums.gltf')
